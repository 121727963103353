import React, { FunctionComponent } from "react"
import MainLayout from "../layouts/MainLayout/MainLayout"
import CursorContextProvider from "../components/CustomCursor/context/CursorContextProvider"
import CookiesPolicy from "../components/CookiesPolicy/CookiesPolicy"

type Props = {}

const Cookies: FunctionComponent<Props> = props => {
  return (
    <CursorContextProvider>
      <MainLayout>
        <CookiesPolicy />
      </MainLayout>
    </CursorContextProvider>
  )
}

export default Cookies
