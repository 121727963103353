import React, { FunctionComponent, useContext } from "react"
import * as Styled from "./CookiesPolicy.styled"
import CursorContext from "../CustomCursor/context/CursorContext"
import Button from "../Button"

type Props = {}

const CookiesPolicy: FunctionComponent<Props> = props => {
  const { events } = useContext(CursorContext)

  return (
    <Styled.Cookies>
      <Styled.Wrapper>
        <Styled.HeadTitle>Polityka plików cookie</Styled.HeadTitle>
        <Styled.ButtonWrapper {...events} href={"/"}>
          <Button style={{ padding: "1rem 2rem", fontSize: "1rem" }}>
            Strona główna
          </Button>
        </Styled.ButtonWrapper>
        <Styled.Title>I. Wstęp </Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Cieszymy się, że odwiedziłeś/aś naszą stronę internetową. Chcemy,
            abyś czuł się na niej komfortowo, dlatego przygotowaliśmy dla Ciebie
            niniejszą politykę plików cookie (czyli popularnych „ciasteczek”).
            Dzięki niej dowiesz się m.in.:
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <ol
            type={"A"}
            style={{ fontWeight: "bold", listStyleType: "lower-latin" }}
          >
            <li>Czym są pliki cookie?</li>
            <li>Jakie pliki cookie i po co stosujemy?</li>
            <li>Jak wpływają one na Twoją prywatność? </li>
            <li>
              Jakie uprawnienia przysługują Ci na mocy RODO* oraz prawa
              telekomunikacyjnego**{" "}
            </li>
          </ol>
        </Styled.TextWrapper>

        <Styled.Title>II. Czym są pliki cookie?</Styled.Title>
        <Styled.TextWrapper>
          <ol type={"1"}>
            <li>
              Najprościej rzecz ujmując, pliki cookie to niewielkie pliki
              tekstowe, które są zapisywane na Twoim komputerze lub smartfonie
              podczas wyświetlania naszej strony internetowej. Istnieją różne
              rodzaje plików cookie. My podzieliliśmy je dla Ciebie na dwie
              grupy:
            </li>
            <ol type={"a"} style={{ listStyleType: "lower-latin" }}>
              <li>Niezbędne do korzystania z naszej strony internetowej.</li>
              <li>Pozostałe.</li>
            </ol>
            <li>
              Pliki cookie, które są niezbędne do korzystania z naszej strony
              internetowej służą m.in. do zapewnienia stabilności jej
              funkcjonowania (mierzą ruch, zabezpieczając nas przed jej
              przeciążeniem), zapamiętania wybranych przez Ciebie preferencji
              dot. prywatności, wypełnienia udostępnionych przez nas formularzy
              on-line oraz monitorowania statusu zalogowania. Te pliki cookie
              stosujemy domyślnie, czyli zapisujemy je na Twoim komputerze lub
              smartfonie w momencie wejścia na naszą stronę internetową (zgodnie
              z art. 173 ust. 3 ustawy prawo telekomunikacyjne).
            </li>
            <li>
              Pozostałe pliki cookie wykorzystujemy jednie wówczas, gdy wyrazisz
              nam na to zgodę. Przeczytasz o nich więcej w następnej części
              naszej Polityki.
            </li>
          </ol>
        </Styled.TextWrapper>

        <Styled.Title style={{ marginBottom: "3rem" }}>
          III. Z jakich plików cookie korzystamy?
        </Styled.Title>
        <div>
          <table>
            <tr>
              <td>
                <Styled.Text>
                  <Styled.Strong>
                    <Styled.Underline>Niezbędne pliki cookie</Styled.Underline>
                  </Styled.Strong>{" "}
                  - zapewniają prawidłowe funkcjonowanie naszej strony i jej
                  podstawowych funkcji. Bez nich nie będziesz mógł prawidłowo
                  korzystać z naszych usług online. Te pliki cookie zwolnione są
                  z obowiązku uzyskania Twojej zgody (art. 173 ust. 3 ustawy
                  prawo telekomunikacyjne).
                </Styled.Text>
              </td>
            </tr>
            <tr>
              <th>Nazwa</th>
              <th>Cel</th>
              <th>Okres przechowywania</th>
            </tr>
            <tr>
              <td>
                <Styled.Strong>_grecaptcha</Styled.Strong>
              </td>
              <td>Ochrona przed spamem</td>
              <td>Czas sesji</td>
            </tr>
            <tr>
              <td>
                <Styled.Strong>cc_cookie</Styled.Strong>
              </td>
              <td>Przechowuje informacje o zgodach na ciasteczka</td>
              <td>6 miesięcy</td>
            </tr>
          </table>
        </div>
        <br></br>
        <br></br>
        <div>
          <table>
            <tr>
              <td>
                <Styled.Text>
                  <Styled.Strong>
                    <Styled.Underline>
                      Analityczne pliki cookie{" "}
                    </Styled.Underline>
                  </Styled.Strong>{" "}
                  - nasza strona korzysta z plików cookie dostarczonych przez
                  nazwa dostawcy analitycznych plików cookie. Analityczne pliki
                  cookie umożliwiają śledzenie liczby i źródeł odwiedzin, dzięki
                  czemu możemy mierzyć i poprawiać wydajność naszej strony
                  internetowej. Ten rodzaj plików cookie pomaga nam zrozumieć,
                  które podstrony są najczęściej lub najrzadziej odwiedzane oraz
                  w jaki sposób odwiedzający poruszają się po naszej stronie.
                  Jeżeli odmówisz zapisania na Twoim komputerze lub smartfonie
                  analitycznych plików cookie, Twoja wizyta nie będzie
                  uwzględniana w naszych statystykach, ale jednocześnie nie
                  ograniczy to dla Ciebie żadnych funkcjonalności na naszej
                  stronie internetowej.
                </Styled.Text>
              </td>
            </tr>
            <tr>
              <th>Nazwa</th>
              <th>Cel</th>
              <th>Okres przechowywania</th>
            </tr>
            <tr>
              <td>
                <Styled.Strong>_gid</Styled.Strong>
              </td>
              <td>
                Cookie Google Analytics. Rejestruje unikalny identyfikator,
                który jest używany do generowania danych statystycznych
                dotyczących sposobu, w jaki odwiedzający korzysta ze strony
                internetowej (http)
              </td>
              <td>1 dzień</td>
            </tr>
            <tr>
              <td>
                <Styled.Strong>ads/gaaudiences</Styled.Strong>
              </td>
              <td>
                Pixel Google Analytics. Wykorzystywane przez Google Ad Words do
                ponownego zaangażowania użytkowników, którzy prawdopodobnie
                przekształcą się w klientów, na podstawie zachowań użytkowników
                online w różnych witrynach internetowych.
              </td>
              <td>Czas trwania sesji</td>
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr>
              <td>
                <Styled.Text>
                  <Styled.Strong>
                    <Styled.Underline>
                      Marketingowe pliki cookie{" "}
                    </Styled.Underline>
                  </Styled.Strong>{" "}
                  - stosujemy te pliki cookie, aby personalizować treści, które
                  są Ci wyświetlane. Marketingowe pliki cookie mogą być
                  wykorzystywane w naszych kampaniach reklamowych, które są
                  prowadzone na stronach internetowych podmiotów trzecich.
                  Jeżeli wyrazisz zgodę na korzystanie z marketingowych plików
                  cookie, możesz otrzymywać informacje o stronach internetowych
                  naszych zaufanych partnerów, na których zareagowałeś na nasze
                  reklamy. Jeżeli zrezygnujesz z marketingowych plików cookie,
                  wyświetlane będą Ci ogólne i niespersonalizowane reklamy.
                  Podobnie jak w przypadku analitycznych plików cookie, jeżeli
                  odmówisz zapisania na Twoim komputerze lub smartfonie
                  marketingowych plików cookie, nie ograniczy to dla Ciebie
                  żadnych funkcjonalności na naszej stronie internetowej.
                </Styled.Text>
              </td>
            </tr>
            <tr>
              <th>Nazwa</th>
              <th>Cel</th>
              <th>Okres przechowywania</th>
            </tr>
            <tr>
              <td>
                <Styled.Strong>_ga</Styled.Strong>
              </td>
              <td>
                Cookie Google Analytics. Rejestruje unikalny identyfikator,
                który służy do generowania danych statystycznych dotyczących
                sposobu korzystania z witryny przez użytkownika (http).
              </td>
              <td>2 lata</td>
            </tr>
            <tr>
              <td>
                <Styled.Strong>_gat</Styled.Strong>
              </td>
              <td>
                Cookie Google Analytics. Używany przez Google Analytics do
                ograniczania szybkości żądań (http).
              </td>
              <td>1 dzień</td>
            </tr>
          </table>
        </div>
        <Styled.Title>IV. Zgoda na instalację plików cookie</Styled.Title>
        <Styled.TextWrapper>
          <ol type={"1"}>
            <li>
              Podczas Twojej wizyty na naszej stronie internetowej zostanie
              wyświetlony baner informujący, że wykorzystuje ona pliki cookie.
              Jeśli wybierzesz opcję "Akceptuj wszystkie", będzie to oznaczać,
              że akceptujesz wszystkie pliki cookie, które są umieszczone na
              naszej stronie internetowej oraz potwierdzasz, że zapoznałeś się z
              informacjami na temat plików cookie oraz celów ich stosowania, a
              także przypadków, w których dane zgromadzone z pomocą plików
              cookie są przekazywane naszym partnerom.{" "}
            </li>
            <li>
              Pamiętaj, że w przypadku niezbędnych plików cookie Twoja zgoda nie
              jest wymagana, ponieważ pliki tego rodzaju zapewniają pełne i
              nieprzerwane funkcjonowanie naszej strony. Te pliki cookie
              zwolnione są z obowiązku uzyskania Twojej zgody zgodnie z art. 173
              ust. 3 ustawy prawo telekomunikacyjne.
            </li>
          </ol>
        </Styled.TextWrapper>
        <Styled.Title>V. Brak zgody na instalację plików cookie</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Jeśli nie chcesz, aby nasze pliki cookie były zapisywane na Twoim
            urządzeniu, możesz wybrać opcję “Akceptuj tylko niezbędne”.
            Wybierając tę opcję odrzucisz wszystkie, prócz technicznie
            niezbędnych, plików cookie, które stosujemy na naszej stronie.{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.Title>VI. Modyfikowanie ustawień plików cookie</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Możesz szczegółowo zarządzać swoimi preferencjami dotyczącymi plików
            cookie, poprzez wybór pola "Pozwól mi wybrać" na wyświetlonym Ci
            banerze cookie.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.Title>VII. Dane osobowe</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Ponieważ niektóre z wykorzystywanych przez nas plików cookie
            stanowią dane osobowe chcemy, abyś znał/a prawa, które przysługują
            Ci na gruncie RODO:
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <ol type={"a"} style={{ listStyleType: "lower-latin" }}>
            <li>
              Administratorem Twoich danych osobowych, czyli podmiotem
              decydującym o celach i sposobach przetwarzania, jest ITENERUM Sp.
              z o.o. z siedzibą w Warszawie 04-881, ul. Patriotów 303.
            </li>
            <li>
              Twoje dane osobowe będą przetwarzane w celu zapewnienia
              podstawowych funkcji naszej strony internetowej. Jeżeli wyraziłeś
              stosowną (dobrowolną) zgodę, Twoje dane będą przetwarzane również
              w celu dostarczenia Ci usług, ofert i komunikatów dopasowanych do
              Twoich preferencji oraz w celu analizowania ruchu na stronie i
              zapewnienia funkcji społecznościowych (w zależności od
              zaznaczonych przez Ciebie preferencji).
            </li>
            <li>
              Więcej informacji na temat zasad przetwarzania danych osobowych i
              przysługujących Ci praw znajdziesz w naszej Polityce prywatności
            </li>
          </ol>
        </Styled.TextWrapper>
        <Styled.Title>VIII. Wycofanie zgody</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Pamiętaj, że w każdym momencie możesz zmienić wyrażone przez siebie
            zgody klikając w przycisk "PREFERENCJE PLIKÓW COOKIE”, umieszczony w
            lewym dolnym rogu naszej strony.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.Title>IX. Zmiany w polityce plików cookie</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Zastrzegamy sobie prawo do zmiany niniejszej polityki plików cookie
            w dowolnym momencie. Wszelkie zmiany w polityce plików cookie będą
            publikowane na stronie www.serioit.pl.
          </Styled.Text>
        </Styled.TextWrapper>
        <br></br>
        <br></br>
        <Styled.TextWrapper style={{ borderTop: "1px solid grey" }}>
          <Styled.Text>
            <br></br>
            <br></br>* Rozporządzenie Parlamentu Europejskiego i Rady (UE)
            2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
            (ogólne rozporządzenie o ochronie danych) (Tekst mający znaczenie
            dla EOG).
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            ** Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U.
            2004 nr 171 poz. 1800 z późn. zm.).
            <br></br>
            <br></br>
          </Styled.Text>
        </Styled.TextWrapper>
      </Styled.Wrapper>
    </Styled.Cookies>
  )
}

export default CookiesPolicy
