import styled, { css } from "styled-components"
import { mediaUp } from "./Mixins"
import ThemeStyle from "./ThemeStyle"

export const StyledBasicPseudo = css`
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${ThemeStyle.colors.white};
`
export const StyledBasicTitle = styled.h2`
  font-family: ${ThemeStyle.fonts.second};
  font-weight: normal;
  font-size: 4.4rem;
  line-height: 1;
  letter-spacing: -2px;
  color: ${ThemeStyle.colors.title};

  span {
    font-family: ${ThemeStyle.fonts.second};
    font-weight: normal;
    font-weight: 800;
    font-size: 4.4rem;
    line-height: 1;
    letter-spacing: -2px;
    color: ${ThemeStyle.colors.main};
  }

  ${mediaUp(ThemeStyle.breakpoints.md, `
		font-size: 5.4rem;
		span {
			font-size: 5.4rem;
		}
	`)}
`
export const StyledBasicSubTitle = styled.h3`
  font-family: ${ThemeStyle.fonts.second};
  font-style: normal;
  font-weight: 300;
  font-size: 3.6rem;
  line-height: 1;
  letter-spacing: -2px;
  color: ${ThemeStyle.colors.subtitle};

  span {
    font-family: ${ThemeStyle.fonts.second};
    font-style: normal;
    font-weight: 800;
    font-size: 3.6rem;
    line-height: 1;
    letter-spacing: -2px;
  }
`
export const StyledBasicText = styled.p`
  font-family: ${ThemeStyle.fonts.main};
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: ${ThemeStyle.colors.text};

  span {
    font-family: ${ThemeStyle.fonts.main};
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.33;
    letter-spacing: -0.5px;
    color: ${ThemeStyle.colors.text};
  }

  ${mediaUp(ThemeStyle.breakpoints.md, `
		font-size: 2.4rem;
		span {
			font-size: 2.4rem;
		}
	`)}
`

export const StyledButton = styled.a`
  background-color: ${ThemeStyle.colors.white};
  color: ${ThemeStyle.colors.main};
  padding: 16px 25px;;
  border-radius: 50px;
  transition: all .3s ease-in-out;
  transition-property: background-color, color;

  &:hover {
    background-color: ${ThemeStyle.colors.whiteHover};
    color: ${ThemeStyle.colors.white};
  }
`
